"use client"

import { type FC } from "react"

import { useSession } from "next-auth/react"

import Icon from "@/components/Icon"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

import LogOutButton from "./LogOutButton"

const UserButton: FC = () => {
  const session = useSession()

  return (
    session.status === "authenticated" && (
      <DropdownMenu>
        <DropdownMenuTrigger
          className="flex size-6 items-center justify-center rounded-md transition hover:bg-zinc-900/5 dark:hover:bg-white/5"
          aria-label="Open user menu"
        >
          <Icon
            type="user"
            className="stroke-secondary-900 dark:stroke-secondary-200"
            size="sm"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <LogOutButton />
        </DropdownMenuContent>
      </DropdownMenu>
    )
  )
}

export default UserButton
